import React, { useEffect, useState } from 'react';
import { App } from 'components/app';
import { ConfigProvider, theme } from 'antd';
import { ClerkProvider } from '@clerk/clerk-react';
import useApiKey from 'hooks/use-api-key';
import { SwitchPage } from 'pages/switch-page/switch-page';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export const MainPage = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { setApiKey } = useApiKey();
  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key');
  }
  useEffect(() => {
    setApiKey('');
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <ClerkProvider
        publishableKey={clerkPubKey}
        appearance={{
          layout: {
            termsPageUrl:
              'https://about.sendforsign.com/about/termsandconditions',
            privacyPageUrl: 'https://about.sendforsign.com/about/privacypolicy',
          },
        }}
      >
        <App>
          <SwitchPage />
        </App>
      </ClerkProvider>
    </ConfigProvider>
  );
};
