import {
  Button,
  Col,
  Row,
  Typography,
  Space,
  Spin,
  Card,
  Steps,
  theme,
  Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractSelector,
  contractValueSelector,
  fillPlaceholderBeforeSelector,
  isDoneSelector,
  placeholdersSelector,
  setApproveModal,
  setContractValue,
  setFillPlaceholderBefore,
  setNotification,
  setPlaceholders,
  setPlaceholdersFilling,
  setShareLinkView,
  setSignModal,
  shareBlockReadySelector,
  shareLinkViewSelector,
} from 'slices/app-slice';
import { useCheckContractValueMutation } from 'slices/contract-api-slice';
import {
  PlaceholderView,
  ShareLinkView,
  SpecialType,
  Tags,
} from 'config/enums';
import { ReadOnlyBlock } from 'components/readonly-block/readonly-block';
import { LockBlock } from 'components/lock-block/lock-block';
import {
  faDownload,
  faSignature,
  faStamp,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetContractEventsByShareLinkQuery } from 'slices/contract-event-api-slice';
import { ContractEvent, ContractSign, Placeholder } from 'config/types';
import { useUpdatePlaceholdersMutation } from 'slices/contract-placeholder-api-slice';
import { changeValueInTag } from 'utils/data-helper';
type Props = {
  contractEvents: ContractEvent[];
  contractSigns: ContractSign[];
  onRefetchSigns: () => void;
};
export const SharePageBlock = ({
  contractEvents,
  contractSigns,
  onRefetchSigns,
}: Props) => {
  const dispatch = useDispatch();
  dayjs.extend(utc);
  const contractValue = useSelector(contractValueSelector);
  const contract = useSelector(contractSelector);
  const isDone = useSelector(isDoneSelector);
  const shareLinkView = useSelector(shareLinkViewSelector);
  const shareBlockReady = useSelector(shareBlockReadySelector);
  const fillPlaceholderBefore = useSelector(fillPlaceholderBeforeSelector);
  const placeholders = useSelector(placeholdersSelector);
  const { Text, Link, Title } = Typography;
  const [spinBtn, setSpinBtn] = useState(false); 
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState<
    { key: string; name: string; value: string }[]
  >([]);
  const [items, setItems] = useState<
    {
      key: string;
      title: string;
    }[]
  >([]);
  const { token } = theme.useToken();
  const [checkContractValue] = useCheckContractValueMutation();
  const [updatePlaceholders] = useUpdatePlaceholdersMutation();

  useEffect(() => {
    if (placeholders && placeholders.length > 0) {
      const filter = placeholders.filter(
        (placeholder) =>
          placeholder.externalRecipientKey === contract.shareLink &&
          placeholder.view.toString() !==
            PlaceholderView.SIGNATURE.toString() &&
          !placeholder.isSpecial
      );
      if (filter && filter.length > 0) {
        let stepsTmp: { key: string; name: string; value: string }[] = [];
        filter.forEach((holder) => {
          stepsTmp.push({
            key: holder.placeholderKey as string,
            name: holder.name as string,
            value: holder.value as string,
          });
        });

        setItems(
          stepsTmp.map((step) => {
            return { key: step.key, title: '' };
          })
        );
        setSteps(stepsTmp);
      } else {
        dispatch(setFillPlaceholderBefore(false));
      }
    }
  }, [placeholders]);

  const handleDownload = async () => {
    setSpinBtn(true);
    // debugger;
    await axios
      .get(`/api/download_pdf?shareLink=${contract.shareLink}&owner=true`, {
        responseType: 'arraybuffer',
      })
      .then((payload) => {
        const content = new Blob([payload.data], {
          type: payload.headers['content-type'],
        });

        const encodedUri = window.URL.createObjectURL(content);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute(
          'download',
          `${
            contract && contract.contractName
              ? contract.contractName
              : 'sendforsign'
          }.pdf`
        );

        link.click();
        setSpinBtn(false);
      });
  };
  const handleBtn = async (e: any) => {
    let changed = false;
    let viewCurrent: ShareLinkView = shareLinkView;
    await checkContractValue({
      shareLink: contract.shareLink,
      changeTime: contractValue.changeTime,
      view: shareLinkView,
    })
      .unwrap()
      .then((payload) => {
        changed = payload.changed;
        if (payload.view) {
          viewCurrent = payload.view;
        }
      });
    if (!changed) {
      if (shareLinkView.toString() === ShareLinkView.SIGN.toString()) {
        dispatch(setSignModal(true));
      } else {
        dispatch(setApproveModal(true));
      }
    } else {
      if (shareLinkView.toString() !== viewCurrent.toString()) {
        dispatch(setShareLinkView(viewCurrent));
        dispatch(
          setNotification({
            text: 'Owner changed the permissions',
          })
        );
      } else {
        dispatch(
          setNotification({
            text: 'Contract updated. Please, reload your page',
          })
        );
      }
    }
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleChange = (e: any, placeholderKey: string) => {
    let stepsTmp: { key: string; name: string; value: string }[] = [...steps];
    const indexPlaceholder = placeholders.findIndex(
      (holder) => holder.placeholderKey === placeholderKey
    );
    let placeholdersTmp: Placeholder[] = placeholders.map(
      (placeholder, index) => {
        if (indexPlaceholder === index) {
          let placeholderTmp = { ...placeholder };
          placeholderTmp.value = e.target.value;
          return placeholderTmp;
        }
        return placeholder;
      }
    );
    const stepIndex = stepsTmp.findIndex((step) => step.key === placeholderKey);
    stepsTmp[stepIndex].value = e.target.value;
    dispatch(setPlaceholders(placeholdersTmp));
    setSteps(stepsTmp);
  };

  const handleContinue = async () => {
    let value = contractValue.contractValue;
    for (let i = 0; i < placeholders.length; i++) {
      if (placeholders[i].isSpecial) {
        if (placeholders[i].specialType) {
          let tag = '';
          switch (placeholders[i].specialType) {
            case SpecialType.DATE:
              tag = Tags.DATE;
              break;
            case SpecialType.FULLNAME:
              tag = Tags.FULLNAME;
              break;
            case SpecialType.EMAIL:
              tag = Tags.EMAIL;
              break;
            case SpecialType.SIGN:
              tag = Tags.SIGN;
              break;
            case SpecialType.INITIALS:
              tag = Tags.INITIALS;
              break;
          }
          value = changeValueInTag(
            tag,
            placeholders[i].id ? (placeholders[i].id as number) : 0,
            placeholders[i].value
              ? placeholders[i].specialType !== SpecialType.SIGN &&
                placeholders[i].specialType !== SpecialType.INITIALS
                ? (placeholders[i].value as string)
                : `<img src='${placeholders[i].value}' alt="signature" />`
              : `{{{${placeholders[i].name as string}}}}`,
            value,
            contract.audit
              ? '#ffffff'
              : placeholders[i].externalRecipientKey &&
                placeholders[i].externalRecipientKey === contract.shareLink
              ? '#a3e8f6'
              : '#f0f0f0',
            placeholders
          );
        }
      } else {
        value = changeValueInTag(
          Tags.PLACEHOLDER,
          placeholders[i].id ? (placeholders[i].id as number) : 0,
          placeholders[i].value
            ? (placeholders[i].value as string)
            : `{{{${placeholders[i].name as string}}}}`,
          value,
          contract.audit
            ? '#ffffff'
            : placeholders[i].externalRecipientKey &&
              placeholders[i].externalRecipientKey === contract.shareLink
            ? '#a3e8f6'
            : '#f0f0f0',
          placeholders
        );
      }
    }
    dispatch(
      setContractValue({
        changeTime: contractValue.changeTime,
        contractValue: value,
      })
    );
    dispatch(setPlaceholdersFilling(true));
    dispatch(setFillPlaceholderBefore(false));
    await updatePlaceholders(placeholders);
  };
  return (
    <Row>
      <Col flex={'auto'}></Col>

      <Col flex="1200px">
        {fillPlaceholderBefore ? (
          <Spin spinning={!shareBlockReady} style={{ marginTop: '30px' }}>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              style={{ minHeight: '40px' }}
            >
              <Col span="24">
                {shareBlockReady && (
                  <Card bordered={true}>
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ display: 'flex' }}
                    >
                      <Space direction="vertical" size={2}>
                        <Title level={4} style={{ margin: '0' }}>
                          Answer the questions below to view your document
                        </Title>
                      </Space>
                      {steps.length > 0 && (
                        <>
                          {steps.length > 1 && (
                            <Steps
                              current={current}
                              items={items}
                              size="small"
                            />
                          )}
                          <Card
                            style={{
                              marginTop: 16,
                              textAlign: 'center',
                              color: token.colorTextTertiary,
                              backgroundColor: token.colorFillAlter,
                              borderRadius: token.borderRadiusLG,
                            }}
                          >
                            <Space
                              direction="vertical"
                              style={{ display: 'flex' }}
                            >
                              <Title level={5} style={{ margin: '0' }}>
                                {steps[current].name}
                              </Title>
                              <Text type="secondary">
                                Enter the value in the field below.
                              </Text>
                              <Input
                                id={steps[current].key}
                                placeholder={`Type here`}
                                value={steps[current].value}
                                onChange={(e) =>
                                  handleChange(e, steps[current].key)
                                }
                              />
                            </Space>
                          </Card>
                          <div style={{ marginTop: 24 }}>
                            {current > 0 && (
                              <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => prev()}
                              >
                                Previous
                              </Button>
                            )}
                            {current < steps.length - 1 && (
                              <Button
                                type="primary"
                                // disabled={continueDisable}
                                onClick={() => next()}
                              >
                                Next
                              </Button>
                            )}
                            {current === steps.length - 1 && (
                              <Button
                                type="primary"
                                onClick={handleContinue}
                                // loading={continueLoad}
                              >
                                Done
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </Space>
                  </Card>
                )}
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="SharingDocFooter"
              style={{
                visibility: !contract.visiblePageBranding
                  ? 'hidden'
                  : 'visible',
              }}
            >
              <Col span="24">
                <Space
                  direction="vertical"
                  style={{ display: 'flex', margin: '24px 0' }}
                  align="center"
                >
                  <Text type="secondary">
                    Powered by{' '}
                    <Link href="https://sendforsign.com" target="_blank">
                      sendforsign.com
                    </Link>
                  </Text>
                </Space>
              </Col>
            </Row>
          </Spin>
        ) : (
          <Spin spinning={!shareBlockReady} style={{ marginTop: '30px' }}>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              style={{ minHeight: '40px' }}
            >
              <Col span="24">
                {shareBlockReady && (
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    {shareLinkView.toString() ===
                      ShareLinkView.SIGN.toString() ||
                    shareLinkView.toString() ===
                      ShareLinkView.APPROVE.toString() ||
                    shareLinkView.toString() ===
                      ShareLinkView.VIEW.toString() ? (
                      <ReadOnlyBlock
                        contractEvents={contractEvents}
                        contractSigns={contractSigns}
                        onRefetchSigns={onRefetchSigns}
                      />
                    ) : (
                      <LockBlock />
                    )}
                  </Space>
                )}
              </Col>
            </Row>
            {shareBlockReady &&
              !isDone &&
              (shareLinkView.toString() === ShareLinkView.SIGN.toString() ||
                shareLinkView.toString() ===
                  ShareLinkView.APPROVE.toString()) && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  className="SharingDocFooter"
                >
                  <Col span="24">
                    <Space
                      direction="vertical"
                      style={{ display: 'flex', marginTop: '24px' }}
                      align="center"
                    >
                      <Button
                        type="primary"
                        icon={
                          shareLinkView.toString() ===
                          ShareLinkView.SIGN.toString() ? (
                            <FontAwesomeIcon icon={faSignature} />
                          ) : (
                            <FontAwesomeIcon icon={faStamp} />
                          )
                        } 
                        onClick={handleBtn}
                      >
                        {shareLinkView.toString() ===
                        ShareLinkView.SIGN.toString()
                          ? 'Sign'
                          : 'Approve'}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              )}
            {shareBlockReady &&
              (isDone ||
                shareLinkView.toString() === ShareLinkView.VIEW.toString()) && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  className="SharingDocFooter"
                >
                  <Col span="24">
                    <Space
                      direction="vertical"
                      style={{ display: 'flex', marginTop: '24px' }}
                      align="center"
                    >
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faDownload} />} 
                        loading={spinBtn}
                        onClick={handleDownload}
                      >
                        Download PDF
                      </Button>
                    </Space>
                  </Col>
                </Row>
              )}
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="SharingDocFooter"
              style={{
                visibility: !contract.visiblePageBranding
                  ? 'hidden'
                  : 'visible',
              }}
            >
              <Col span="24">
                <Space
                  direction="vertical"
                  style={{ display: 'flex', margin: '24px 0' }}
                  align="center"
                >
                  <Text type="secondary">
                    Powered by{' '}
                    <Link href="https://sendforsign.com" target="_blank">
                      sendforsign.com
                    </Link>
                  </Text>
                </Space>
              </Col>
            </Row>
          </Spin>
        )}
      </Col>
      <Col flex={'auto'}></Col>
    </Row>
  );
};
