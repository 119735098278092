/* eslint-disable jsx-a11y/iframe-has-title */
import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiKeySelector, currentClientSelector } from 'slices/app-slice';
const lazyWithRetry = (componentImport) =>
  React.lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
const AIComponent = lazyWithRetry(() =>
  import('sendforsign').then((module) => ({ default: module.AiAssistant }))
);
export const AIPage = () => {
  const history = useHistory();
  const apiKey = useSelector(apiKeySelector);
  const currentClient = useSelector(currentClientSelector);

  if (!apiKey || !currentClient || currentClient.clientKey == null) {
    history.push('/overview');
    return null;
  }
  
  return (
    <Suspense
      fallback={
        <Spin spinning={true} style={{ width: '100%' }} percent={'auto'} />
      }
    >
      <AIComponent
        apiKey={apiKey}
        clientKey={
          currentClient &&
          currentClient.clientKey &&
          typeof currentClient.clientKey !== 'undefined'
            ? currentClient.clientKey
            : ''
        }
      />
    </Suspense>
  );
};
