import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractSelector,
  contractValueSelector,
  isDoneSelector,
  pdfFileLoadSelector,
  setNotification,
  setShareLinkView,
  setSignModal,
  shareBlockReadySelector,
  shareLinkViewSelector,
} from 'slices/app-slice';
import styles from './pdf-viewer.module.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { useResizeDetector } from 'react-resize-detector';
import { Spin } from 'antd';
import cn from 'classnames';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';
import { PagePlaceholder } from 'config/types';
import { PlaceholderView, ShareLinkView, SpecialType } from 'config/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { useCheckContractValueMutation } from 'slices/contract-api-slice';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
type Props = {
  pagePlaceholders?: PagePlaceholder[];
  onLoad?: (data: any) => void;
  forPrint?: boolean;
};
export const PdfViewer = ({
  pagePlaceholders,
  onLoad,
  forPrint = false,
}: Props) => {
  const dispatch = useDispatch();
  const pdfFileLoad = useSelector(pdfFileLoadSelector);
  const contract = useSelector(contractSelector);
  const contractValue = useSelector(contractValueSelector);
  const isDone = useSelector(isDoneSelector);
  const shareLinkView = useSelector(shareLinkViewSelector);
  const shareBlockReady = useSelector(shareBlockReadySelector);
  const [pdfData, setPdfData] = useState<ArrayBuffer>();
  const [numPages, setNumPages] = useState(1);

  const { getArrayBuffer } = useSaveArrayBuffer();
  const [checkContractValue] = useCheckContractValueMutation();

  const { width, ref } = useResizeDetector();

  useEffect(() => {
    const getValue = async () => {
      console.log('pdfFileLoad', pdfFileLoad);
      const arrayBuffer: ArrayBuffer = (await getArrayBuffer(
        'pdfFile'
      )) as ArrayBuffer;
      return setPdfData(arrayBuffer);
    };
    getValue();
  }, [pdfFileLoad]);

  const handleBtn = async () => {
    let changed = false;
    let viewCurrent: ShareLinkView = shareLinkView;
    await checkContractValue({
      shareLink: contract.shareLink,
      changeTime: contractValue.changeTime,
      view: shareLinkView,
    })
      .unwrap()
      .then((payload) => {
        changed = payload.changed;
        if (payload.view) {
          viewCurrent = payload.view;
        }
      });
    if (!changed) {
      dispatch(setSignModal(true));
    } else {
      if (shareLinkView.toString() !== viewCurrent.toString()) {
        dispatch(setShareLinkView(viewCurrent));
        dispatch(
          setNotification({
            text: 'Owner changed the permissions',
          })
        );
      } else {
        dispatch(
          setNotification({
            text: 'Contract updated. Please, reload your page',
          })
        );
      }
    }
  };
  // console.log('pagePlaceholders', pagePlaceholders);

  const adjustTextSize = () => {
    const divs = document.getElementsByClassName('hola'); // Получаем все элементы с классом 'hola'
    if (divs.length === 0) return; // Проверяем, существуют ли элементы
    console.log('adjustTextSize');

    Array.from(divs).forEach((div) => {
      // Применяем логику к каждому элементу
      const element = div as HTMLElement; // Cast to HTMLElement
      element.style.fontFamily = 'sans-serif';
      const fontSize = 14;
      let currentFontSize = fontSize;

      // Проверяем, помещается ли текст
      while (
        element.scrollHeight > element.clientHeight
      ) {
        currentFontSize -= 1;
        element.style.fontSize = `${currentFontSize}px`; // Use the casted element
        if (currentFontSize <= 1) {
          break; // Предотвращаем бесконечный цикл
        }
      }
    });
  };

  // Если текст может изменяться динамически, наблюдаем за изменениями
  useEffect(() => {
    const observer = new MutationObserver(adjustTextSize);
    const targetNodes = document.getElementsByClassName('hola'); // Получаем все элементы с классом 'hola'
    Array.from(targetNodes).forEach((node) => {
      observer.observe(node, {
        childList: true,
        characterData: true,
        subtree: true,
      });
    });
    return () => {
      observer.disconnect();
    };
  }, []); 
  return (
    <div ref={ref}>
      <Document
        loading={<Spin spinning={!shareBlockReady} />}
        file={pdfData}
        onLoadSuccess={({ numPages }) => {
          // console.log('onLoadSuccess');
          setNumPages(numPages);
        }}
        onSourceError={() => {
          console.log('PdfViewer onSourceError');
        }}
        onLoadError={() => {
          console.log('PdfViewer onLoadError');
        }}
        onError={() => {
          console.log('PdfViewer error');
        }}
      >
        {new Array(numPages).fill(0).map((_, i) => {
          return (
            <Page
              renderTextLayer={false}
              width={1000}
              // height={1.4 * width}
              pageNumber={i + 1}
              // scale={scale}
              onLoadSuccess={(data) => {
                console.log('onLoadSuccess', new Date().getMilliseconds());

                adjustTextSize();
                if (onLoad) {
                  onLoad({ pageDetails: data, docRef: ref });
                }
              }}
            >
              <div
                id={`page_${i}`}
                style={{
                  position: 'absolute',
                  margin: 0,
                  padding: 0,
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                }}
              >
                {pagePlaceholders &&
                  pagePlaceholders.length > 0 &&
                  pagePlaceholders
                    .filter(
                      (pagePlaceholder) =>
                        pagePlaceholder.pageId.toString() === i.toString()
                    )
                    .map((pagePlaceholder) => {
                      // console.log('pageId', i, pagePlaceholder.pageId);
                      return (
                        <div
                          id={`insertion_${pagePlaceholder?.placeholderKey?.replaceAll(
                            '-',
                            '_'
                          )}-${pagePlaceholder.pageId}-${pagePlaceholder.id}`}
                          style={{
                            width: `${pagePlaceholder.width - 2}px`,
                            height: `${pagePlaceholder.height - 1}px`,
                            transform: `translate3d(${
                              pagePlaceholder.positionX - 2
                            }px, ${pagePlaceholder.positionY - 1}px, 0)`,
                            WebkitTransform: `translate3d(${
                              pagePlaceholder.positionX - 2
                            }px, ${pagePlaceholder.positionY - 1}px, 0)`,
                          }}
                          className={cn(
                            forPrint
                              ? styles.placeholderPrint
                              : pagePlaceholder.owner
                              ? styles.placeholderOwner
                              : styles.placeholderOther,
                            !isDone &&
                              pagePlaceholder.owner &&
                              !pagePlaceholder.base64 &&
                              (pagePlaceholder.view?.toString() ===
                                PlaceholderView.SIGNATURE.toString() ||
                                (pagePlaceholder.isSpecial &&
                                  pagePlaceholder.specialType.toString() ===
                                    SpecialType.SIGN.toString()))
                              ? styles.classWithHover
                              : ''
                          )}
                          onClick={() => {
                            if (
                              !isDone &&
                              pagePlaceholder.owner &&
                              !pagePlaceholder.base64 &&
                              (pagePlaceholder.view?.toString() ===
                                PlaceholderView.SIGNATURE.toString() ||
                                (pagePlaceholder.isSpecial &&
                                  pagePlaceholder.specialType.toString() ===
                                    SpecialType.SIGN.toString()))
                            ) {
                              handleBtn();
                            }
                          }}
                        >
                          {pagePlaceholder.view?.toString() ===
                            PlaceholderView.SIGNATURE.toString() ||
                          (pagePlaceholder.isSpecial &&
                            pagePlaceholder.specialType.toString() ===
                              SpecialType.SIGN.toString()) ? (
                            <>
                              {pagePlaceholder.base64 ? (
                                <img
                                  alt="signature"
                                  src={pagePlaceholder.base64}
                                  width={pagePlaceholder.width}
                                  height={pagePlaceholder.height}
                                  style={{ objectFit: 'contain' }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faSignature}
                                  style={{ padding: '4px' }}
                                />
                              )}
                            </>
                          ) : (
                            <div
                              className="hola"
                              style={{
                                fontFamily: 'Inter!important',
                                fontWeight: 500,
                                color: 'black',
                                wordBreak: 'break-word', // Break long words onto the next line
                                overflowWrap: 'break-word',
                                whiteSpace: 'normal', // Allow text to wrap
                                maxHeight: '100%', // Ensure it doesn't exceed the container
                              }}
                            >
                              {pagePlaceholder.value
                                ? pagePlaceholder.value
                                : pagePlaceholder.name}
                            </div>
                          )}
                        </div>
                      );
                    })}
              </div>
            </Page>
          );
        })}
      </Document>
    </div>
  );
};
